import React, { useLayoutEffect, useState } from 'react'
import ListaNombres from './ListaNombres'
import Logo from '../assets/logo.png'
import Marquee from "react-fast-marquee"
import ReactLoading from 'react-loading'
import axios from 'axios'
import './ListaNombres.css'

function PantallaNombresYKT() {
    const query = new URLSearchParams(location.search)
    let [loading, setLoading] = useState(true)
    let [banner1, setBanner1] = useState([])
    let [banner2, setBanner2] = useState([])
    let mujeres = query.get("seccion") == 'mujeres' ? true : false

    let url = 'https://script.google.com/macros/s/AKfycbxvqtZEdMqTPezfcjr5af4wItPKxmD049wCuZ00posirhJ4FbTkLeEsLrDC95ZLXYaA/exec'

    useLayoutEffect(() => {
        setTimeout(() => { window.location.reload() }, 3600000)

        axios.get(url)
            .then((response) => {
                setLoading(false)
                setBanner1(mujeres ? response.data.Banner1Mujeres ? response.data.Banner1Mujeres : [] : response.data.Banner1 ? response.data.Banner1 : []);
                setBanner2(mujeres ? response.data.Banner2Mujeres ? response.data.Banner2Mujeres : [] : response.data.Banner2 ? response.data.Banner2 : [])
            })
            .catch((error) => { console.log(error) })

        let data = JSON.stringify({ "date": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()} / ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}` })

        axios.post(url, data)
            .then((response) => { /* console.log(response) */ })
            .catch((error) => { console.log(error) })
    }, [])

    return (
        <>
            {
                !loading ?
                    <>
                        <div className="contenidosListaNombres">
                            <div className='footer1'>
                                <Marquee speed={100}>
                                    {
                                        banner1.map((item, index) => {
                                            return (<p key={item.Leyenda} style={{ width: `${item.Leyenda.length * 2 + 20}vw` }}>{item.Leyenda}</p>)
                                        })
                                    }
                                </Marquee>
                            </div>

                            <div className='contenido'>
                                {/* <div className='tv'>
                                    <img style={{ "width": "10vw", "height": "auto" }} src={Logo} alt="Tehilatenu" />

                                    <h3>לעילוי נשמת זכייה בת סליא</h3>
                                </div> */}

                                <ListaNombres cantidadNombres={10} anonimo={'mostrar'} pantallaYKT={true} />
                            </div>

                            <div className='footer2'>
                                <Marquee speed={100}>
                                    {
                                        banner2.map((item, index) => {
                                            return (<p key={item.Leyenda} style={{ width: `${item.Leyenda.length * 2 + 20}vw` }}>{item.Leyenda}</p>)
                                        })
                                    }
                                </Marquee>
                            </div>
                        </div>
                    </>
                    : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>

            }
        </>
    )
}

export default PantallaNombresYKT